import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const casestudiesCarousel = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__casestudiescarousel' );

			if ( el.length === 0 ) { return false; }
		}

		initCasestudiesCarousel();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__casestudiescarousel' ) ) {
					initCasestudiesCarousel();
				}
			});
		}
	};

	const initCasestudiesCarousel = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						buttonClick();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initCasestudiesCarousel failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			buttonClick();
		}
	};

	const buttonClick = function() {
		let 	buttons = document.querySelectorAll( '.casestudies-carousel__btn' );



        buttons.forEach( function( button ) {
            const   id = button.dataset.target,
                    target = document.querySelector( '#' + id ),
                    direction = button.dataset.control,
					counter = document.querySelector( 'span[data-target="' + id + '"]' );



            button.addEventListener( 'click', function( e ) {
                let     current = target.dataset.current,
                        total = target.dataset.total;

                let     cards = target.querySelectorAll( '.casestudies-carousel__card' );



                cards.forEach( function( card ) {
                    let     thisPosition = Number( card.dataset.position ),
							thisInitial = Number( card.dataset.initial );

					//// Up/down controls
                    if ( direction === 'up' ) {
                        if ( thisPosition >= total ) {
                            card.dataset.position = 1;
                        }
                        else {
                            card.dataset.position = Number( thisPosition + 1 );
                        }
                    }
                    else if ( direction === 'down' ) {
                        if ( thisPosition <= 1 ) {
                            card.dataset.position = total;
                        }
                        else {
                            card.dataset.position = Number( thisPosition - 1 );
                        }
                    }

					//// Update current card and counter
					if ( Number( card.dataset.position ) === 1 ) {
						target.dataset.current = thisInitial;

						counter.innerText = thisInitial;
					}
                });



                button.blur();

                e.preventDefault();
            });
        });
	}

	return {
		init: init
	}
})();



// Initialize
domReady( casestudiesCarousel.init );
import.meta.webpackHot?.accept( casestudiesCarousel.init ); // See https://webpack.js.org/api/hot-module-replacement