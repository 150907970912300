import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';


const videoembed = ( ( err ) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__videoembed' );

			if ( el.length === 0 ) { return false; }
		}

		initVideoEmbed();

        // Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__videoembed' ) ) {
					initVideoEmbed();
				}
			});
		}
	};

	const initVideoEmbed = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize the carousel. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;
		
			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						videoEmbedAspect();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initVideoEmbed failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);
		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore
			//  we just initialize the carousel immediately.
			videoEmbedAspect();
		}
	};

	const videoEmbedAspect = function() {
		let 	parents = document.querySelectorAll( '.has__videoembed' );

	

		parents.forEach( function( parent ) {
			let 	container = parent.querySelector( '.video__container' ),
					embed = container.querySelector( 'iframe' );
			
			let 	width = embed.getAttribute( 'width' ),
					height = embed.getAttribute( 'height' );
			

					
			if ( width && height ) {
				container.style.aspectRatio = width + '/' + height;
			}
			else {
				container.style.aspectRatio = '16 / 9';
			}
		});
	};

	return {
		init: init
	};
})();

// Initialize
domReady( videoembed.init );
import.meta.webpackHot?.accept( videoembed.init ); // See https://webpack.js.org/api/hot-module-replacement