import { domReady } from '@roots/sage/client';



// Import scripts for specific views
import './views/blocks/scrollview.js';
import './views/blocks/accordion.js';
import './views/blocks/tabs.js';
import './views/blocks/casestudies-carousel.js';
import './views/blocks/inthemedia.js';
import './views/blocks/lity.js';
import './views/blocks/videoembed.js';
import './views/blocks/graph.js';

import './views/sections/site-header.js';



/**
 * app.main
 * JS to run across the entire site. Use this sparingly! Most of your JS
 * should be confined into various /views/ JS partials to maintain
 * organization / encapsulation.
 */
const main = async (err) => {
  if ( err ) { console.error(err); } // handle hmr errors

  // application code goes here...
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady( main );
import.meta.webpackHot?.accept( main );
