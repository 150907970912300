import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const accordion = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__accordion' );

			if ( el.length === 0 ) { return false; }
		}

		initAccordion();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__accordion' ) ) {
					initAccordion();
				}
			});
		}
	};

	const initAccordion = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						accordionClick();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initAccordion failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			accordionClick();
		}
	};

	const accordionClick = function() {
		let 	headers = document.querySelectorAll( '.accordion__section__header' );

		headers.forEach( function( header ) {
			header.addEventListener( 'click', function( e ) {
				let 	id = this.dataset.target,
						section = this.closest( id ),
						accordion = section.closest( '.accordion' ),
						sectionAll = accordion.querySelectorAll( '.accordion__section' );
				
				sectionAll.forEach( ( sectionSingle ) => {
					if ( sectionSingle.id != section.id ) {
						sectionSingle.classList.remove( 'accordion__open' );
					}
				});

				section.classList.toggle( 'accordion__open' );

				this.blur();
				e.preventDefault();
			});
		});
	}

	return {
		init: init
	}
})();



// Initialize
domReady( accordion.init );
import.meta.webpackHot?.accept( accordion.init ); // See https://webpack.js.org/api/hot-module-replacement