import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const inthemedia = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__inthemedia' );

			if ( el.length === 0 ) { return false; }
		}

		initInthemediaSlider();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__inthemedia' ) ) {
					initInthemediaSlider();
				}
			});
		}
	};

	const initInthemediaSlider = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						buttonClick();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initInthemediaSlider failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			buttonClick();
		}
	};

	const buttonClick = function() {
		let 	buttons = document.querySelectorAll( '.card__slider__btn' );



        buttons.forEach( function( button ) {
            const   id = button.dataset.target,
                    target = document.querySelector( '#' + id ),
                    direction = button.dataset.control,
					counter = document.querySelector( 'span[data-target="' + id + '"]' );



            button.addEventListener( 'click', function( e ) {
                let     current = Number( target.dataset.current ),
                        next,
                        total = Number( target.dataset.total );
                
                let     currentSlide = target.querySelector( '.card__slide[data-count="' + current + '"]' ),
                        currentSlideX = currentSlide.offsetLeft,
                        currentSlideWidth = currentSlide.offsetWidth;
                
                let     nextSlide,
                        nextSlideX;



                if ( !button.disabled ) {
                    if ( direction === 'left' ) {
                        next = current - 1;

                        document.querySelector( '.card__slider__btn[data-control="right"][data-target="' + id + '"]' ).disabled = false;

                        if ( next === 1 ) {
                            button.disabled = true;
                        }
                        else {
                            button.disabled = false;
                        }
                    }
                    else if ( direction === 'right' ) {
                        next = current + 1;

                        document.querySelector( '.card__slider__btn[data-control="left"][data-target="' + id + '"]' ).disabled = false;

                        if ( next === total ) {
                            button.disabled = true;
                        }
                        else {
                            button.disabled = false;
                        }
                    }

                    nextSlide = target.querySelector( '.card__slide[data-count="' + next + '"]' );
                    nextSlideX = nextSlide.offsetLeft;

                    target.style.transform = 'translateX(-' + nextSlideX + 'px)';

                    current = next;
                    target.dataset.current = current;
                    counter.innerText = current;
                }



                this.blur();

                e.preventDefault();
            });
        });
	}

	return {
		init: init
	}
})();



// Initialize
domReady( inthemedia.init );
import.meta.webpackHot?.accept( inthemedia.init ); // See https://webpack.js.org/api/hot-module-replacement