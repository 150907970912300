import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const tabs = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__tabs' );

			if ( el.length === 0 ) { return false; }
		}

		initTabs();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__tabs' ) ) {
					initTabs();
				}
			});
		}
	};

	const initTabs = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						tabClick();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initTabs failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			tabClick();
		}
	};

	const tabClick = function() {
		let 	tabMenu = document.querySelectorAll( '.casestudies__tabs' );

		let 	tabButtons = document.querySelectorAll( '.casestudies__control' );



		tabMenu.forEach( function( menu ) {
			let 	menuWidth = menu.scrollWidth;

			let 	menuParent = menu.parentElement,
					menuParentWidth = menuParent.scrollWidth;
			
			let 	scrollLeft = menuParent.querySelector( '.casestudies__tabs__scroll--left' ),
					scrollRight = menuParent.querySelector( '.casestudies__tabs__scroll--right' );

			

			if ( menuWidth > menuParentWidth ) {
				let 	difference = menuParentWidth - menuWidth;



				menuParent.classList.add( 'casestudies__tabs-container--overflow' );
				menu.classList.add( 'casestudies__tabs--overflow' );



				scrollLeft.addEventListener( 'click', function( e ) {
					menuParent.classList.remove( 'casestudies__tabs-container--overflow--right' );
					menuParent.classList.add( 'casestudies__tabs-container--overflow--left' );

					menu.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth',
					});

					this.blur();

					e.preventDefault();
				});

				scrollRight.addEventListener( 'click', function( e ) {
					menuParent.classList.remove( 'casestudies__tabs-container--overflow--left' );
					menuParent.classList.add( 'casestudies__tabs-container--overflow--right' );

					menu.scrollTo({
						top: 0,
						left: menuWidth,
						behavior: 'smooth',
					});

					this.blur();

					e.preventDefault();
				});
			}
		});



        tabButtons.forEach( function( button ) {
            const   id = button.dataset.target,
                    cohort = button.dataset.cohort,
                    target = document.querySelector( 'div[data-id=' + id + '][data-cohort=' + cohort + ']' );
            
            const   allTabs = document.querySelectorAll( 'div[data-id=' + id + ']' );



            button.addEventListener( 'click', function( e ) {
                allTabs.forEach( function ( tab ) {
                    tab.classList.add( 'hidden' );
                });

                target.classList.remove( 'hidden' );

                tabButtons.forEach( function( other ) {
                    other.classList.remove( 'active' );
                });

                button.classList.add( 'active' );
                button.blur();

                e.preventDefault();
            });
        });
	}

	return {
		init: init
	}
})();



// Initialize
domReady( tabs.init );
import.meta.webpackHot?.accept( tabs.init ); // See https://webpack.js.org/api/hot-module-replacement