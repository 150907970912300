import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';
import Viewer from 'viewerjs';


const graph = ( ( err ) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__graph' );

			if ( el.length === 0 ) { return false; }
		}

		initGraph();

        // Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__graph' ) ) {
					initGraph();
				}
			});
		}
	};

	const initGraph = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize the carousel. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;
		
			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						graphLightbox();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initGraph failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);
		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore
			//  we just initialize the carousel immediately.
			graphLightbox();
		}
	};

	const graphLightbox = function() {
		let 	target = document.querySelector( '.has__graph' ),
				maxOffsetPercentage = 0.9;

		const viewer = new Viewer( target, {
			navbar: false,
			title: false,
			toolbar: false,
			keyboard: false,
			focus: false,
			loading: false,
			loop: false,
			rotatable: false,
			tooltip: false,
			transition: false,
			zIndex: 9999,
			inheritedAttributes: [
				'crossOrigin', 'decoding', 'isMap', 'referrerPolicy', 'sizes', 'srcset', 'useMap'
			],
			zoom: function( e ) {
				let 	width = window.innerWidth;

				// Force zoom at center
				if ( e.detail.originalEvent ) {
					e.preventDefault();
				
					viewer.zoomTo( e.detail.ratio );
				}

				// Prevent zooming too far out
				if ( e.detail.ratio < 0.25 ) {
					e.preventDefault();
				}

				if ( width <= 700 ) {
					// Max zoom is 400% window width on mobile
					if ( viewer.imageData.width > ( width * 4 ) ) {
						if ( e.detail.ratio > e.detail.oldRatio ) {
							e.preventDefault();
						}
					}
				}
				else {
					// Max zoom is 200% window width on larger
					if ( viewer.imageData.width > ( width * 2 ) ) {
						if ( e.detail.ratio > e.detail.oldRatio ) {
							e.preventDefault();
						}
					}
				}
			},
			zoomed: function ( e ) {
				let 	detail = e.detail;

				if ( detail.ratio < detail.oldRatio ) {
					let 	viewerData = viewer.viewerData,
							imageData = viewer.imageData;

					let 	maxOffsetHorizontal = viewerData.width * maxOffsetPercentage,
							maxOffsetVertical = viewerData.height * maxOffsetPercentage;

					let 	left = imageData.x,
							top = imageData.y,
							right = viewerData.width - ( left + imageData.width ),
							bottom = viewerData.height - ( top + imageData.height );

					let 	x = 0,
							y = 0;
		
					if ( right > 0 && right > maxOffsetHorizontal ) {
					  	x = maxOffsetHorizontal - right;
					}
		
					if ( left > 0 && left > maxOffsetHorizontal ) {
					  	x = maxOffsetHorizontal - left;
					}
		
					if ( bottom > 0 && bottom > maxOffsetVertical ) {
					  	y = bottom - maxOffsetVertical;
					}
		
					if ( top > 0 && top > maxOffsetVertical) {
					  	y = top - maxOffsetVertical;
					}
		
					// Move the image into view if it is invisible
					if ( x !== 0 || y !== 0 ) {
					  	viewer.move( x, y );
					}
				  }
			},
			move: function ( e ) {
				let		viewerData = viewer.viewerData,
						imageData = viewer.imageData;
				
				let		maxOffsetHorizontal = viewerData.width * maxOffsetPercentage,
						maxOffsetVertical = viewerData.height * maxOffsetPercentage;
				
				let 	detail = e.detail;

				let		left = detail.x,
						top = detail.y,
						right = viewerData.width - (left + imageData.width),
						bottom = viewerData.height - (top + imageData.height);
	  
				if (
					// Move left
					( detail.x < detail.oldX && right > 0 && right > maxOffsetHorizontal )

					// Move right
					|| ( detail.x > detail.oldX && left > 0 && left > maxOffsetHorizontal )

					// Move up
					|| ( detail.y < detail.oldY && bottom > 0 && bottom > maxOffsetVertical )

					// Move down
					|| ( detail.y > detail.oldY && top > 0 && top > maxOffsetVertical )
				) {
				  	e.preventDefault();
				}
			},
		});
	};

	return {
		init: init
	};
})();

// Initialize
domReady( graph.init );
import.meta.webpackHot?.accept( graph.init ); // See https://webpack.js.org/api/hot-module-replacement