import {domReady} from '@roots/sage/client';



const siteHeader = ( (err) => {
	if (err) { console.error(err); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit
		const el = document.querySelectorAll( '#nav' );
		if ( el.length === 0 ) { return false; }

		navSetup();
	};

	const navSetup = () => {
		const 	body = document.querySelector( 'body' ),
				nav = document.querySelector( '#nav' ),
				app = document.querySelector( '#app' );
		
		const 	navButton = document.querySelector( '#nav__button' );

		let		navTransparent = ( nav.dataset.transparent === 'true' );

		let 	windowWidth = window.innerWidth,
				windowHeight = window.innerHeight;

		let 	prevScrollPos = window.scrollY;



		// Widow control on load
		widowControl( windowWidth );



		// Adjust nav/menu variables once loaded fully
		document.onreadystatechange = () => {
			if ( document.readyState === 'complete' ) {
				app.classList.add( 'loaded' );

				// Add current scroll position variable
				document.documentElement.style.setProperty( '--scrollY', window.scrollY );

				// Add scrollbar width variable when loaded
				document.documentElement.style.setProperty( '--scrollbar', ( window.innerWidth - document.documentElement.clientWidth ) + 'px');
			}
		};



		// Change variables/widows on window resize
		window.addEventListener( 'resize', function() {
			let 	newWindowWidth = window.innerWidth;

			windowHeight = window.innerHeight;

			document.documentElement.style.setProperty( '--scrollY', window.scrollY );
			document.documentElement.style.setProperty( '--scrollbar', ( window.innerWidth - document.documentElement.clientWidth ) + 'px');

			if ( windowWidth != newWindowWidth ) {
				clearTimeout( window.resizedFinished );
	
				window.resizedFinished = setTimeout( function(){
					widowControl( newWindowWidth );

					windowWidth = window.innerWidth;
				}, 250 );
			}
		});



		// Scroll up to show menu
		window.addEventListener( 'scroll', function() {
			const currentScrollPos = window.scrollY;

			document.documentElement.style.setProperty( '--scrollY', currentScrollPos );

			if ( prevScrollPos > currentScrollPos ) {
				body.classList.remove( 'scrolled' );
			} else {
				if ( currentScrollPos > 100 ) {
					body.classList.add( 'scrolled' );
					body.classList.add( 'scrolled-up' );

					if ( navTransparent ) {
						setTimeout( () => {
							nav.classList.remove( 'colorscheme--transparent' );
						}, 500 );
					}
				}
			}

			if ( currentScrollPos <= 100 ) {
				body.classList.remove( 'scrolled-up' );

				if ( navTransparent ) {
					nav.classList.add( 'colorscheme--transparent' );
				}
			}

			prevScrollPos = currentScrollPos;
		});



		// Mobile nav button
		navButton.addEventListener( 'click', function( e ) {
			body.classList.toggle( 'nav--open' );

			this.blur();
			e.preventDefault();
		});
	};

	const widowControl = function( width ) {
		let 	widowElements = document.querySelectorAll( '.content p' );

		widowElements.forEach( function( element ) {
			element.innerHTML = element.innerHTML.replace( /&nbsp;/g, ' ' );
		});

		if ( width > 700 ) {
			widowElements.forEach( function( element ) {
				element.innerHTML = element.innerHTML.replace( /\s((?=(([^\s<>]|<[^>]*>)+))\2)\s*$/, '&nbsp;$1' );
			});
		}
	};

	return {
		init: init
	}
})();



// Initialize
domReady( siteHeader.init );
import.meta.webpackHot?.accept( siteHeader.init ); // See https://webpack.js.org/api/hot-module-replacement