import { domReady } from '@roots/sage/client';
import { isAdmin, isGutenbergActive } from '../../helpers.js';



const scrollview = ( (err) => {
	if ( err ) { console.error( err ); } // handle hot module replacement errors from webpack

	const init = () => {
		// Ensure target element is present on page, otherwise exit (front-end only)
		if ( !isAdmin() ) {
			const el = document.querySelectorAll( '.has__scrollview' );

			if ( el.length === 0 ) { return false; }
		}

		initScrollView();

		// Re-initialize carousels when they are remounted (ie. toggled to "preview") in Gutenberg
		if ( window.acf ) {
			window.acf.addAction( 'remount', ( $el ) => {
				if ( !$el[0] ) { return; }

				if ( $el[0].classList.contains( 'has__scrollview' ) ) {
					initScrollView();
				}
			});
		}
	};

	const initScrollView = () => {
		if ( isAdmin() ) {
			//  On admin screens we need to wait until Gutenberg is active and usable
			//  before we can initalize Flickity. We try every 100ms for a maximum of
			//  10 seconds (100 intervals), and if Gutenberg isn't active at that point
			//  we give up and exit.
			const max_int = 100;
			let int = 0;

			const interval = setInterval( () => {
				if ( int < max_int ) {
					if ( isGutenbergActive() ) {
						scrollIntoView();

						clearInterval( interval );
					}

					int++;
				} else {
					console.log( 'initScrollCiew failed to find Gutenberg, exiting' );

					clearInterval( interval );
				}
			}, 100);

		} else {
			//  If we're not on an admin screen we must be on the front-end, so therefore we just initialize the carousels immediately.
			scrollIntoView();
		}
	};

	const scrollIntoView = function() {
		const 	blocksView = document.querySelectorAll( '.has__scrollview' );

		let		windowHeight = window.innerHeight,
				currentScrollPos = window.pageYOffset;



		//// When scrolled into view, apply scroll class to block
		window.addEventListener( 'scroll', function() {
			currentScrollPos = window.pageYOffset;



			blocksView.forEach( function( block ) {
				const	multiplier = Number( block.dataset.multiplier );

				let 	blockTop = block.offsetTop,
						blockHeight = block.offsetHeight,
						windowTrigger = currentScrollPos + ( windowHeight * multiplier );

				if ( windowTrigger < blockTop ) {
					block.classList.remove( 'scrolled' );
				}
				if ( windowTrigger >= blockTop ) {
					block.classList.add( 'scrolled' );
				}
			});
		});

		//// If page loads and already scrolled, apply class immediately
		blocksView.forEach( function( block ) {
			const	multiplier = Number( block.dataset.multiplier );

			let 	blockTop = block.offsetTop,
					blockHeight = block.offsetHeight,
					windowTrigger = currentScrollPos + ( windowHeight * multiplier );

			if ( windowTrigger < blockTop ) {
				block.classList.remove( 'scrolled' );
			}
			if ( windowTrigger >= blockTop ) {
				block.classList.add( 'scrolled' );
			}
		});



		window.addEventListener( 'resize', function() {
			windowHeight = window.innerHeight;
		});
	}

	return {
		init: init
	}
})();



// Initialize
domReady( scrollview.init );
import.meta.webpackHot?.accept( scrollview.init ); // See https://webpack.js.org/api/hot-module-replacement